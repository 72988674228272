import { SEARCH_PAGE_PATH } from 'public/src/pages/product_app/router/constants.js'
// import pre_search from 'src/pages/product_app/store/modules/pre_search/index'

const { newSearchContainer } = gbCommonInfo || {}


const config = {
  featureKey: 'search_to_home_judge', // 场景key
  singleKey: true, // 是否单例，如果是单例，那么只会有一个sessionId
  closeSessionStorage: true, // 是否使用 sessionStorage 存储数据
  saNames: [
    {
      saName: 'click_goods_list',
      limit: 5
    },
    {
      saName: 'click_goods_list_addcar',
      limit: 5
    },
    {
      saName: 'click_add_bag',
      limit: 5
    },
  ], 
  targetNames: [
    {
      name: newSearchContainer ? 'search-product-list' : 'product-list-v2',
      url: `/${SEARCH_PAGE_PATH}`
    },
  ],
  handleAction(data, collectData) { // 过滤数据, 每次埋点都会触发
    const { activity_name, page_param, page_name, activity_param } = data
    const { src_module, src_tab_page_id } = page_param

    // 主站搜索，排除店铺搜索、专题搜索、趋势搜索等
    if (src_module !== 'search') return null

    // 点击事件 
    if (activity_name == 'click_goods_list' || activity_name == 'click_goods_list_addcar') {
      // 限制页面为搜索结果页
      if (page_name === 'page_search') {
        return {
          src_tab_page_id: src_tab_page_id,
          page_name: page_name
        }
      }
      return null
    }

    // 加车事件
    if (activity_name == 'click_add_bag') {
      // 限制加车成功
      if (activity_param?.result !== '1') return null

      // 商详的加车成功事件需要限制为从搜索结果页跳转进来，（判断src_tab_page_id是否一致）
      if(page_name === 'page_goods_detail') {
        // 限制为主商品加车
        if (activity_param?.activity_from !== 'main') return null
        const timestamp = Object.keys(collectData)[0]
        const searchListResult = collectData?.[timestamp]?.['click_goods_list'] || []
        const listSrcTabPageId = searchListResult?.[searchListResult.length - 1]?.src_tab_page_id
        if (listSrcTabPageId !== src_tab_page_id) return null
        return {
          src_tab_page_id: src_tab_page_id,
          page_name: page_name
        }
      }
      if (page_name === 'page_search') {
        // 限制页面为搜索结果页
        return {
          src_tab_page_id: src_tab_page_id,
          page_name: page_name
        }
      }
      return null
    }
  },
  onTrigger({ collectData }) {
    const timestamp = Object.keys(collectData)[0]
    return {
      isClick: collectData?.[timestamp]?.['click_goods_list']?.length > 0 || collectData?.[timestamp]?.['click_goods_list_addcar']?.length > 0,
      isAddCart: collectData?.[timestamp]?.['click_add_bag']?.length > 0,
    }
  }
}

export default config
