// 全局埋点行为收集

const EXPOSE_GOODS_SA_NAME = 'expose_goods_list,expose_module_goods_list,expose_auto_rcmd_info_flow,expose_auto_rcmd_goods_list'
const CLICK_GOODS_SA_NAME = 'click_goods_list,click_module_goods_list,click_auto_rcmd_info_flow,click_auto_rcmd_goods_list'
const CLICK_CHECKOUT_SA_NAME = 'click_checkout'
const CLICK_ADD_COLLECT_SA_NAME = 'click_add_collect'
const CLICK_ADD_BAG_SA_NAME = 'click_add_bag'

const _getSessionItemByList = (list = [], part = ',') => {
  return list?.length ? list.join(part) : undefined
}

// 时间戳倒序，取最近40个商品
const getLast40Items = (list = []) => _getSessionItemByList(list.sort((a, b) => b.split(',')[1] - a.split(',')[1]).slice(0, 40))

// 校验有效的goodsId，为数字
const isValidGoodsId = (goodsId) => {
  return /^\d+$/.test(goodsId)
}

const dealGoodsListCommon = (acc, cur, { getGoodsListArr, getGoodsId }) => {
  const { page_name, timestamp, activity_param } = cur || {}

  const goodsListArr = getGoodsListArr(activity_param) || []
  if (!goodsListArr.length) return

  goodsListArr.forEach(item => {
    const goodsId = getGoodsId(item)
    if (isValidGoodsId(goodsId)) {
      acc.push(_getSessionItemByList([goodsId, timestamp, page_name]))
    }
  })
}

/**
 * 根据goods_list 或 info_flow获取埋点行为信息
 * @param {Array} actionsData 埋点信息
 * @param {Boolean} hasInfoFlow 包含info_flow的情况
 * @returns ['goodsId1,timestamp1,page_name1', 'goodsId2,timestamp2,page_name2', ...]
 */
const getGoodsListInfoArr = (actionsData, hasInfoFlow) => {
  const goodsListInfoArr = actionsData
    .reverse()
    .reduce((acc, cur) => {
      // goods_list
      dealGoodsListCommon(
        acc, 
        cur,
        {
          getGoodsListArr: (activity_param) => activity_param?.goods_list?.split(','),
          getGoodsId: (item) => item?.split('`')[0]
        }
      )

      // info_flow
      if (hasInfoFlow) {
        dealGoodsListCommon(
          acc, 
          cur,
          {
            getGoodsListArr: (activity_param) => activity_param?.info_flow?.split('`')?.[3]?.split('|'),
            getGoodsId: (item) => item?.split('_')?.[1]
          }
        )
      }

      return acc
    }, [])

  return goodsListInfoArr
}

// 过滤埋点数据，保留必要字段
const filterAanlysisInfo = (data) => {
  const { activity_name, page_name, timestamp, activity_param } = data || {}
  const goodsListArr = activity_param?.goods_list?.split(',')
  const infoFlowArr = activity_param?.info_flow?.split('`')?.[3]?.split('|')
  console.log('全局埋点收集 ========> ', `${activity_name}`, {
    page_name, 
    timestamp, 
    goodsId: goodsListArr?.length 
      ? goodsListArr.map(_ => isValidGoodsId(_.split('`')[0]) ? _.split('`')[0] : 'invalidGoodsId')
      : infoFlowArr?.length
        ? infoFlowArr.map(_ => isValidGoodsId(_.split('_')[1]) ? _.split('_')[1] : 'invalidGoodsId')
        : 'noGoodsId'
  })
  return {
    activity_name,
    page_name,
    timestamp,
    activity_param: {
      goods_list: activity_param?.goods_list,
      info_flow: activity_param?.info_flow
    }
  }
}

const config = {
  featureKey: 'commonActions',  // 特征key
  keepAlive: true, // 配置的埋点触发，就开始收集
  singleKey: true, // 单个sessionId
  closeSessionStorage: false, // 是否使用 sessionStorage 存储数据
  saNames: [ // 所经过的埋点name
    {
      saName: EXPOSE_GOODS_SA_NAME,
      limit: 20, // 就限制20，一般列表首屏曝光都有4个商品，最少也有2个
      limitTime: 60 * 1000 // 只记录最近60s内的数据
    },
    {
      saName: CLICK_GOODS_SA_NAME,
      limit: 40,
      limitTime: 60 * 1000 // 只记录最近60s内的数据
    },
    {
      saName: CLICK_CHECKOUT_SA_NAME,
      limit: 40,
      limitTime: 60 * 1000 // 只记录最近60s内的数据
    },
    {
      saName: CLICK_ADD_COLLECT_SA_NAME,
      limit: 40,
      limitTime: 60 * 1000 // 只记录最近60s内的数据
    },
    {
      saName: CLICK_ADD_BAG_SA_NAME,
      limit: 40,
      limitTime: 60 * 1000 // 只记录最近60s内的数据
    }
  ],
  handleAction(data) { // 每次收集到数据触发, data为每个埋点数据
    if (
      EXPOSE_GOODS_SA_NAME.includes(data.activity_name) ||
      CLICK_GOODS_SA_NAME.includes(data.activity_name) ||
      CLICK_CHECKOUT_SA_NAME.includes(data.activity_name) ||
      CLICK_ADD_COLLECT_SA_NAME.includes(data.activity_name) ||
      CLICK_ADD_BAG_SA_NAME.includes(data.activity_name)
    ) {
      // 过滤取消收藏的埋点
      if (CLICK_ADD_COLLECT_SA_NAME.includes(data.activity_name)) {
        if (data.activity_param?.is_cancel !== '1') return null
      }
      return filterAanlysisInfo(data)
    }

    return data
  },
  onTrigger({ collectData, routerAction }) {
    const {
      [EXPOSE_GOODS_SA_NAME]: exposeGoodsList = [],
      [CLICK_GOODS_SA_NAME]: clickGoodsList = [],
      [CLICK_CHECKOUT_SA_NAME]: clickCheckoutList = [],
      [CLICK_ADD_COLLECT_SA_NAME]: clickAddCollectList = [],
      [CLICK_ADD_BAG_SA_NAME]: clickAddBagList = []
    } = collectData[Object.keys(collectData)[0]]

    const result = {
      top40ExposeGoodsList: getLast40Items(getGoodsListInfoArr(exposeGoodsList, true)),
      top40ClickGoodsList: getLast40Items(getGoodsListInfoArr(clickGoodsList, true)),
      top40ClickCheckoutList: getLast40Items(getGoodsListInfoArr(clickCheckoutList)),
      top40ClickAddCollectList: getLast40Items(getGoodsListInfoArr(clickAddCollectList)),
      top40ClickAddBagList: getLast40Items(getGoodsListInfoArr(clickAddBagList))
    }

    return result
  }
}

export default config
