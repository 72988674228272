import { SEARCH_PAGE_PATH } from 'public/src/pages/product_app/router/constants.js'

const { newSearchContainer } = gbCommonInfo || {}

const SEARCH_KEYWORDS_SA_NAME = 'click_top_site_search,click_icon_search,click_list_top_navigation'
const CLICK_GOODS_SA_NAME = 'click_goods_list,click_module_goods_list'
const CLICK_ADDCART_SA_NAME = 'click_goods_list_addcar'
const EXPOSE_GOODS_SA_NAME = 'expose_goods_list'
const CLICK_ADD_BAG_SA_NAME = 'click_add_bag'

const config = {
  featureKey: 'search_to_home', // 场景key
  singleKey: true, // 是否单例，如果是单例，那么只会有一个sessionId
  closeSessionStorage: true, // 是否使用 sessionStorage 存储数据
  // 需要收集的埋点
  saNames: [
    {
      saName: SEARCH_KEYWORDS_SA_NAME,
      limit: 5
    },
    {
      saName: CLICK_GOODS_SA_NAME,
      limit: 30
    },
    {
      saName: CLICK_ADDCART_SA_NAME,
      limit: 30
    },
    {
      saName: EXPOSE_GOODS_SA_NAME,
      limit: 30
    },
    {
      saName: CLICK_ADD_BAG_SA_NAME,
      limit: 30
    },
  ], 
  timestampLength: 10, // 时间戳长度配置
  targetNames: [
    {
      name: 'pre_search',
      url: '/presearch'
    },
    {
      name: newSearchContainer ? 'search-product-list' : 'product-list-v2',
      url: `/${SEARCH_PAGE_PATH}`
    },
  ],
  handleAction(data) { // 过滤数据, 每次埋点都会触发
    // 处理搜索词的来源页面
    const processedDataFromSearchkeywords = handleFromPage4SearchKeywords(data)
    if (processedDataFromSearchkeywords !== 'next') return processedDataFromSearchkeywords

    // 过滤跳转商详
    const clickToDetailSaInfo = filterClickToDetail(data)
    if (clickToDetailSaInfo !== 'next') return clickToDetailSaInfo

    // 过滤跳转商详
    const clickAddCartSaInfo = filterClickAddCart(data)
    if (clickAddCartSaInfo !== 'next') return clickAddCartSaInfo

    // 过滤商品曝光
    const goodsExposedSaInfo = filterGoodsExposed(data)
    if (goodsExposedSaInfo !== 'next') return goodsExposedSaInfo

    // 过滤加车成功
    const addCartSuccSaInfo = filterAddCartSucc(data)
    if (addCartSuccSaInfo !== 'next') return addCartSuccSaInfo

    return null
  },
  onTrigger({ collectData }) {
    const timestamp = Object.keys(collectData)[0] // 时间戳
    // 点击搜索词
    const clickSearchAction = handleSearchContent(collectData, timestamp, SEARCH_KEYWORDS_SA_NAME)

    // 点击行为
    const clickAction = handleCollectData(collectData, timestamp, CLICK_GOODS_SA_NAME)
    // 快速加车点击行为
    const clickAddCartAction = handleCollectData(collectData, timestamp, CLICK_ADDCART_SA_NAME)
    // 加车行为
    const addCartAction = handleCollectData(collectData, timestamp, CLICK_ADD_BAG_SA_NAME)
    // 曝光行为
    const exposedAction = handleCollectData(collectData, timestamp, EXPOSE_GOODS_SA_NAME)
    if (!clickSearchAction && !clickAction.length && !addCartAction.length && !exposedAction.length) {
      return null
    }
    const result = {}
    if (clickSearchAction) {
      result.rec_ctx = {
        rt_f_cycle_infoFlow_search: clickSearchAction, // 点击搜索词
      }
    }
    if (clickAction.length || addCartAction.length || exposedAction.length || clickAddCartAction.length) {
      result.rec_user = {
        100032: clickAction.length ? clickAction : undefined, // 点击行为
        100033: addCartAction.length ? addCartAction : undefined, // 加车行为
        100034: exposedAction.length ? exposedAction : undefined, // 曝光行为
        100035: clickAddCartAction.length ? clickAddCartAction : undefined, // 点击加车行为
      }
    }
    return result
    // const triggerData = {
    //   rec_ctx: {
    //     rt_f_cycle_infoFlow_search: clickSearchAction ? clickSearchAction : undefined, // 点击搜索词
    //   },
    //   rec_user: {
    //     100032: clickAction.length ? clickAction : undefined, // 点击行为
    //     100033: addCartAction.length ? addCartAction : undefined, // 加车行为
    //     100034: exposedAction.length ? exposedAction : undefined, // 曝光行为
    //   }
    // }
  }
}

const handleSearchContent = (collectData, timestamp, saName) => {
  const collectInfo = collectData?.[timestamp]?.[saName]  || []
  
  const result = collectInfo.sort((a, b) => b?.[2] - a?.[2]).flat(1).join(',')
  return result
}

const handleCollectData = (collectData, timestamp, saName) => {
  const collectInfo = collectData?.[timestamp]?.[saName]  || []
  
  const result = collectInfo.sort((a, b) => b[1] - a[1]).flat(1)
  return result
}

// 点击搜索词
const handleFromPage4SearchKeywords = (data) => {
  const { activity_name, activity_param, timestamp,  } = data
  const isSearchKeywords = SEARCH_KEYWORDS_SA_NAME.split(',').includes(activity_name)
  if (!isSearchKeywords) return 'next'
  
  if(data?.activity_name === 'click_list_top_navigation') {
    if(!activity_param?.top_navigation_query) return null
    const result_content = activity_param?.top_navigation_query?.split('`') || []
    const keywords = result_content?.[result_content.length - 2]
    if (!keywords) return null
    // return {
    //   keywords,
    //   word_id: 0, 
    //   timestamp: timestamp,
    // }
    // 图文导航没有word_id, 写死0
    return [keywords, 0, timestamp]
  } else {
    const result_content = activity_param?.result_content?.split('`') || []
    if (!result_content.length || !activity_param?.search_content) return null
    const words_id = (result_content?.[7] === '-' || !result_content?.[7]) ? 0 : result_content?.[7]
    return [activity_param?.search_content, words_id, timestamp]
  }
}

// 过滤商品点击
const filterClickToDetail = (data) => {
  const { activity_name, activity_param, timestamp } = data
  const isClickGoods = CLICK_GOODS_SA_NAME.split(',').includes(activity_name)
  if (!isClickGoods) return 'next'

  const isNotClickToDetail = activity_param?.style !== 'detail' // 点击跳转商详

  if (isClickGoods && isNotClickToDetail) return null

  const goods_id = activity_param?.goods_list?.split('`')?.[0] || ''

  if(!goods_id) return null

  return [Number(goods_id), Number(timestamp)]
}

// 过滤商品点击加车
const filterClickAddCart = (data) => {
  const { activity_name, activity_param, timestamp } = data
  const isClickAddCart = activity_name === CLICK_ADDCART_SA_NAME
  if (!isClickAddCart) return 'next'
  const goods_id = activity_param?.goods_list?.split('`')?.[0] || ''

  if(!goods_id) return null

  return [Number(goods_id), Number(timestamp)]
}

// 过滤商品曝光
const filterGoodsExposed = (data) => {
  const { activity_name, activity_param, timestamp } = data
  const isGoodsExposed = activity_name === EXPOSE_GOODS_SA_NAME
  if (!isGoodsExposed) return 'next'

  // 过滤是搜索结果页的曝光
  if (data?.page_name !== 'page_search') return null
  const result = []
  activity_param?.goods_list?.split(',')?.forEach(item => {
    const goods_id = item?.split('`')?.[0] || ''
    if(!goods_id || isNaN(Number(goods_id))) return null
    result.push(Number(goods_id), Number(timestamp))
  })
  if(!result.length) return null
  return result
}

// 过滤加车成功
const filterAddCartSucc = (data) => {
  const { activity_name, activity_param, timestamp } = data
  const isClickAddBag = activity_name === CLICK_ADD_BAG_SA_NAME
  if (!isClickAddBag) return 'next'

  const isNotAddBagSucc = data?.activity_param?.result !== '1' // 非加车成功

  if (isClickAddBag && isNotAddBagSucc) return null

  const goods_id = activity_param?.goods_id || ''

  if(!goods_id) return null

  return [Number(goods_id), Number(timestamp)]
}


export default config
