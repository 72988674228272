import GeeTest from 'public/src/pages/common/RiskCommon/libs/geetest/index.js'
import VerfyGeeTest from 'public/src/pages/common/RiskCommon/libs/geetest/verfy_index'
import schttp from 'public/src/services/schttp'
import { setCookie } from '@shein/common-function'
import verifyManager from '@shein-risk/si_verify'
import { parseQueryString } from '@shein/common-function'

// const Instance = new GeeTest()

// 流量层挑战豁免cookie名字
const llbs_cookie_name = '_f_c_llbs_'

/**
 * 极验挑战类初始化
 * @param {*} type 初始化类型, icon: 点选， slide: 滑块
 */
function geetestRegister(type, Instance) {
  return new Promise((resolve) => {
    Instance.register(resolve, type)
  })
}
export async function runOtherChallege(captcha_type, api_risk_id) {
  const { 'risk-id': origin_risk_id } = parseQueryString(location.search)
  const iconTypes = { 903: 'js_challenge', 904: 'gee_slide', 905: 'gee_click' }
  if (window?.gbCommonInfo?.GB_RISK_JS_CHALLEGE == 1 && captcha_type == 903 && api_risk_id) {
    window?.AntiDevices?.report('js_challenge')
  }
  verifyManager.init({
    axiosInstance: schttp, // 你的HTTP实例对象
    rate: 5000, //0-10000之间
  })
  const options = {
    validate_type: iconTypes[captcha_type],
    validate_scene: 'anti_crawler_RT',
    validate_channel: 1,
    validate_param: {
      decision_id: api_risk_id || origin_risk_id || '',
    }
  }
  return new Promise((resolve) => {
    verifyManager.doVerify(options, data => {
      const { isSuccess } = data
      resolve({ status: isSuccess ? 'success' : 'error', challenge: '', risk: '', validate_token: data?.info?.validate_token || '' })
      // eslint-disable-next-line no-unused-vars
    }).catch(error => {
      resolve({ status: 'error', challenge: '', risk: '' })
    })
  })
}
/**
 * 执行极验挑战效果
 * @param {string} captcha_type 挑战类型，901: 极验滑块，902：极验点选
 * @param {number} cookie_duration cookie豁免有效期, 设置cookie有效期的时候会 cookie_duration * 2，防止有效期短于网关的有效期
 * @returns {Object} res 响应结果
 * @returns {string} res.status 状态值，geetest_error: 极验错误，success: 极验挑战成功, geetest_close: 用户手动关闭
 * @returns [string] res.msg 描述值
 * @returns [string] res.challenge 极验流水号
 * @returns [string] res.risk 极验判断是否有风险
 * 
 */
export async function runGeetestChallege(captcha_type) {
  // const querys = parseQueryString(location.search)
  const Instance = window.GB_RISK_CHALLEGE_LIMIT == 1 ? new VerfyGeeTest() : new GeeTest()
  // const { captcha_type, cookie_duration = 0 } = querys
  // const iconTypes = { 901: 'slide', 902: 'icon' }
  const iconTypes = window.GB_RISK_CHALLEGE_LIMIT == 1 ? { 901: 'gee_slide', 902: 'gee_click' } : { 901: 'slide', 902: 'icon' }
  const type = iconTypes[captcha_type] || ''

  if (!type) { // 极验类型不支持
    const response = { status: 'geetest_error', msg: 'captcha_type not support' }
    return response
  }

  // 初始化特定极验类型
  const vv = await geetestRegister(type, Instance)

  const { status: initStatus } = vv
  if (initStatus == 'error') { // 初始化报错
    const response = { status: 'geetest_error', msg: 'geetest init error' }
    return response
  }

  const { status, challenge, risk } = await Instance.verifyPromise('', 'flow_challenge')

  if (status == 'close') { // 用户操作关闭
    const response = { status: 'geetest_close', msg: '' }
    return response
  }

  if (status == 'error') { // 挑战流程报错
    const response = { status: 'geetest_error', msg: 'geetest request error' }
    return response
  }

  return { status: 'success', challenge, risk }

}

/**
 * 执行网关流量拦截获取豁免加密cookie
 * @param {string} captcha_type 挑战类型，901: 极验滑块，902：极验点选
 * @param {number} cookie_duration cookie豁免有效期, 设置cookie有效期的时候会 cookie_duration * 2，防止有效期短于网关的有效期
 * @returns {Object} res 响应结果
 * @returns {string} res.status  状态值，geetest_error: 极验错误，success: 获取风控cookie成功, geetest_close: 用户手动关闭
 * @returns [string] res.code 风控返回的状态码
 * @returns [string] res.challenge 极验流水号
 * @returns [string] res.llbs 风控返回的加密cookie
 */
export async function runFlowChallenge(captcha_type, cookie_duration, api_risk_id) {

  let response = {}
  let validate_scene = ''
  const types = [901, 902, 903, 904, 905]
  if (!types.includes(Number(captcha_type))) {
    return { status: 'error', challenge: '', risk: '' }
  }
  try {
    // 埋点上报-实时引擎
    const { 'risk-id': origin_risk_id } = parseQueryString(location.search)
    if (origin_risk_id) {
      window?.sa('set', 'setPageData', window.SaPageInfo)
      window?.sa('send', 'pageEnter', { start_time: new Date().getTime() })
      window.onbeforeunload = function () { window?.sa('send', 'pageLeave', { end_time: new Date().getTime() }) }
      const anti_in = window?.AntiIn?.syncGetAllEncrypted(window?.AntiIn.Channel.M) || null
      const risk_url = encodeURIComponent(location.href)
      const armortoken = window?.__ArmorToken || window?.sessionStorage.getItem('armorToken') || window?.localStorage.getItem('armorToken') || null
      window?.sa('send', {
        activity_name: 'expose_risk_envinfo',
        activity_param: {
          origin_risk_id,
          anti_in,
          armortoken,
          risk_url
        }
      })
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error', error)
  }
  switch (Number(captcha_type)) {
    case 901:
    case 902:
      validate_scene = 'anti_crawler_gee'
      response = await runGeetestChallege(captcha_type)
      break
    default:
      validate_scene = 'anti_crawler_RT'
      response = await runOtherChallege(captcha_type, api_risk_id)
      break
  }
  const { status, challenge, risk, validate_token } = response

  if (status != 'success') return response

  const params = {
    challenge,
    captcha_type,
    gRisk: risk,
    validate_scene,
    validate_token: validate_token || ''
  }

  const data = await schttp({ url: '/api/risk/flow_check', method: 'POST', data: params }).catch(err => err)

  const risk_response = {
    status: data.status == 0 ? 'success' : 'risk_error',
    code: data.status,
    llbs: data.llbs || ''
  }

  if (data.status == 0) {
    // 设置流量层挑战豁免cookie，有效期为网关拦截时间(cookie_duration)的2倍，防止正常用户的有效期小于网关设置的有效期
    const timeout = parseInt(cookie_duration) || 0
    // eslint-disable-next-line
    setCookie({ key: llbs_cookie_name, value: data.llbs, end: timeout * 2 })
  }

  return risk_response
}
