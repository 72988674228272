import UserKey from '../UserKey'
import schttp from 'public/src/services/schttp'
import { USERINFO_KEY } from '@login/common/contants.js'
import { setLocalStorage, getLocalStorage, removeLocalStorage } from '@shein/common-function'

const STORAGE_KEY = 'UserInfo'

const CACHE_KEY = USERINFO_KEY

const CLIENT_STORAGE_EXPIRE = 18 * 24 * 60 * 60 * 1000

const ALLOWED_PROPERTIES = [ 'AuthToken', 'login_phone', 'phone', 'area_code', 'modifyPassword', 'account_type', 'add_time', 'login_time_last', 'member_id', 'nickname', 'init_password' ]
class UserInfo extends UserKey {
  static canHandle(key) {
    return key === STORAGE_KEY
  }

  async getAuthUserInfo () {
    const data = await schttp({
      url: '/api/auth/authUserInfo/get',
    })
    const { code, info } = data || {}
    if (code == 0) {
      return info || {}
    }
    return {}
  }

  isNotEmpty(obj) {
    if(typeof obj !== 'object' || obj == null) return false
    return Object.keys(obj).length > 0
  }

  filterObjectByKeys(obj, keys) {
    return keys.reduce((acc, key) => obj[key] !== undefined ? ({ ...acc, [key]: obj[key] }) : acc, {})
  }

  async get (options = {}) {
    const noCache = options?.noCache || false
    if (!this.isLogin()) {
      removeLocalStorage(CACHE_KEY)
      return {}
    }
    let result = {}
    if(noCache !== true){
      result = getLocalStorage(CACHE_KEY) || {}
    }
    if (!this.isNotEmpty(result)) {
      result = await this.getAuthUserInfo()
      this.isNotEmpty(result) && setLocalStorage({
        key: CACHE_KEY,
        value: this.filterObjectByKeys(result || {}, ALLOWED_PROPERTIES),
        expire: CLIENT_STORAGE_EXPIRE,
      })
    }
    return result
  }

  set (value, { } = {}) {
    const params = this.filterObjectByKeys(value || {}, ALLOWED_PROPERTIES)
    this.isNotEmpty(params) && setLocalStorage({
      key: CACHE_KEY,
      value: params,
      expire: CLIENT_STORAGE_EXPIRE,
    })
  }

  getSync () {
    if (!this.isLogin()) {
      removeLocalStorage(CACHE_KEY)
      return {}
    }
    const data = getLocalStorage(CACHE_KEY)
    return data || {}
  }
}

export default UserInfo

