/* eslint-disable no-unused-vars */
/* eslint-disable @shein-aidc/common/notDirectUseCookie */
import { removeSessionStorage, setSessionStorage, getSessionStorage } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import { getCookie } from '@shein/common-function'

class UserKey {
  static for({ key }) {
    const userKeyClass = UserKey.classes.find(candidate => candidate.canHandle(key))

    return new userKeyClass({ key })
  }

  static canHandle(key) {
    return true
  }

  constructor({ key }) {
    this.key = key
    this.cache = new Map()
  }

  get() {
    return getSessionStorage(this.key)
  }

  set(value, options) {
    setSessionStorage({ key: this.key, value: value })
  }

  clearStorage() {
    removeSessionStorage(this.key)
  }

  /**
   * 对内提供给 UserKeys 判断
   * @returns Boolean
   */
  isLogin() {
    return !!getCookie('memberId')
  }

  /**
   * 对内提供给 UserKeys 获取 memberId
   * @returns Boolean
   */
  getMemberId () {
    return getCookie('memberId') || ''
  }

  async getIpCountryApi() {
    try {
      let res = await schttp({
        method: 'POST',
        url: '/user-api/address/update_country',
        useBffApi: true,
      })

      if (res.code == 0) {
        const { countryId = '', countryAbbr = '' } = res?.info || {}

        return {
          countryId,
          countryAbbr
        }
      } else {
        return {}
      }
    } catch (e) {
      return {}
    }
  }

  async getUgidApi() {
    try {
      const res = await schttp({
        method: 'get',
        useBffApi: true,
        url: '/user-api/common/userinfo_ugid'
      })
      return res?.info?.ugid || ''
    } catch (e) {
      return ''
    }
  }
}

export default UserKey
