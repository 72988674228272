import { SEARCH_PAGE_PATH } from 'public/src/pages/product_app/router/constants.js'

const { newSearchContainer } = gbCommonInfo || {}

const SEARCH_KEYWORDS_SA_NAME = 'click_top_site_search,click_icon_search,click_list_top_navigation'
const CLICK_GOODS_SA_NAME = 'click_goods_list,click_module_goods_list'
const PAGE_VIEW_SA_NAME = 'page_view'
const CLICK_ADD_BAG_SA_NAME = 'click_add_bag'
const CLICK_ADD_COLLECT_SA_NAME = 'click_add_collect'
const CLICK_CHECKOUT_SA_NAME = 'click_checkout'

const GOODS_BROWSE_MAX_LEN = 30
const GOODS_BROWSE_PV_KEY_PRIVITE = '_goods_browse_pv_key'

const handleFromPage4SearchKeywords = (data, collectData) => {
  const isSearchKeywords = SEARCH_KEYWORDS_SA_NAME.includes(data?.activity_name)
  if (!isSearchKeywords) return 'next'

  const isClickTopNav = data?.activity_name === 'click_list_top_navigation'
  if (isClickTopNav && !data?.activity_param?.top_navigation_query) return null

  const isClickIconSearch = data?.activity_name === 'click_icon_search'
  if (isClickIconSearch && !data?.activity_param?.search_content) return null

  const cycleId = Object.keys(collectData)[0]
  const clickTopSiteSearch = collectData?.[cycleId]?.[SEARCH_KEYWORDS_SA_NAME]
  const pvArr = collectData?.[cycleId]?.[PAGE_VIEW_SA_NAME] || []
  const startCyclePageName = collectData?.[cycleId]?._fromPv?.page_name
  const filterData = {
    activity_name: data?.activity_name,
    page_name: data?.page_name,
    timestamp: data?.timestamp,
    activity_param: {
      top_navigation_query: data?.activity_param?.top_navigation_query || undefined,
      search_content: data?.activity_param?.search_content || undefined
    },
    page_param: {
      pagefrom: data?.page_param?.pagefrom
    },
  }

  // cycle开始的时候
  if (!clickTopSiteSearch?.length) {
    filterData.processedEntryFrom = startCyclePageName
    return filterData
  }

  // 在一个cycle内
  const exclueLeavePvArr = pvArr.filter(item => !item.end_time) // 过滤掉离开的pv
  let entryFromIndex = exclueLeavePvArr.length - 2
  // 不是在预搜页点击的搜索按钮（目前在商详和列表 可以直接点击搜索框的搜索按钮）
  if (filterData.page_name !== 'page_pre_search') {
    entryFromIndex = exclueLeavePvArr.length - 1
  }

  if (entryFromIndex < 0) return filterData

  filterData.processedEntryFrom = exclueLeavePvArr[entryFromIndex].page_name

  return filterData
}

const filterClickToDetail = (data) => {
  const isClickGoods = CLICK_GOODS_SA_NAME.includes(data?.activity_name)
  if (!isClickGoods) return 'next'

  const isNotClickToDetail = data?.activity_param?.style !== 'detail' // 点击跳转商详

  if (isClickGoods && isNotClickToDetail) return null

  return {
    activity_name: data?.activity_name,
    timestamp: data?.timestamp,
    page_name: data?.page_name,
    activity_param: {
      goods_list: data?.activity_param?.goods_list
    }
  }
}

const filterAddCartSucc = (data) => {
  const isClickAddBag = data?.activity_name === CLICK_ADD_BAG_SA_NAME
  if (!isClickAddBag) return 'next'

  const isNotAddBagSucc = data?.activity_param?.result !== '1' // 非加车成功

  if (isClickAddBag && isNotAddBagSucc) return null

  return {
    activity_name: data?.activity_name,
    timestamp: data?.timestamp,
    page_name: data?.page_name,
    activity_param: {
      goods_id: data?.activity_param?.goods_id
    }
  }
}

const filterAddCollectSucc = (data) => {
  const isClickAddCollect = data?.activity_name === CLICK_ADD_COLLECT_SA_NAME
  if (!isClickAddCollect) return 'next'

  const isNotAddCollectSucc = data?.activity_param?.is_cancel !== '1' // 非收藏成功

  if (isClickAddCollect && isNotAddCollectSucc) return null

  return {
    activity_name: data?.activity_name,
    timestamp: data?.timestamp,
    page_name: data?.page_name,
    activity_param: {
      goods_id: data?.activity_param?.goods_id
    }
  }
}

const filterClickCheckout = (data) => {
  const isClickAddCollect = data?.activity_name === CLICK_CHECKOUT_SA_NAME
  if (!isClickAddCollect) return 'next'

  return {
    activity_name: data?.activity_name,
    timestamp: data?.timestamp,
    page_name: data?.page_name,
    activity_param: {
      goods_list: data?.activity_param?.goods_list
    }
  }
}

const handleSearchKeywords = (collectData, cycleId) => {
  const clickTopSiteSearchInfo = collectData?.[cycleId]?.[SEARCH_KEYWORDS_SA_NAME]
  if (clickTopSiteSearchInfo?.length) {
    const cycleKeywordsInfo = clickTopSiteSearchInfo.reduce(
      (acc, cur) => {
        // 点击图文转query
        if (cur.activity_name === 'click_list_top_navigation') {
          if (cur.activity_param?.top_navigation_query) {
            const topNavigationQuery = cur?.activity_param?.top_navigation_query.split('`')
            const searchedKeywords = decodeURI(cur?.page_param?.pagefrom?.split('`')?.[2]?.split('>').join(' ').replace(',', ' ') || '')
            const topNavigationWords = topNavigationQuery[topNavigationQuery?.length - 2]
            // 倒序（新的行为放前面）
            acc.keywords.unshift(`${searchedKeywords} ${topNavigationWords}`)
            acc.timeStamp.unshift(cur?.timestamp)
            acc.entryFrom.unshift(cur?.processedEntryFrom)
          }
          return acc
        }

        // 倒序（新的行为放前面）
        acc.keywords.unshift(cur?.activity_param?.search_content.replace(',', ' '))
        acc.timeStamp.unshift(cur?.timestamp)
        acc.entryFrom.unshift(cur?.processedEntryFrom)

        return acc
      },
      { keywords: [], timeStamp: [], entryFrom: [] }
    )

    return cycleKeywordsInfo
  }

  return { keywords: [], timeStamp: [], entryFrom: [] }
}

const handleClickGoods = (collectData, cycleId) => {
  const clickGoodsListInfo = collectData?.[cycleId]?.[CLICK_GOODS_SA_NAME]
  if (clickGoodsListInfo?.length) {
    const cycleClickGoodsInfo = clickGoodsListInfo.reduce(
      (acc, cur) => {
        // 倒序（新的行为放前面）
        acc.goodsIds.unshift(cur?.activity_param?.goods_list?.split('`')?.[0])
        acc.timeStamp.unshift(cur?.timestamp)
        acc.pageName.unshift(cur?.page_name)

        return acc
      },
      { goodsIds: [], timeStamp: [], pageName: [] }
    )

    return cycleClickGoodsInfo
  }

  return { goodsIds: [], timeStamp: [], pageName: [] }
}

const handleClickAddBag = (collectData, cycleId) => {
  const clickAddBagInfo = collectData?.[cycleId]?.[CLICK_ADD_BAG_SA_NAME]
  if (clickAddBagInfo?.length) {
    const cycleCartInfo = clickAddBagInfo.reduce(
      (acc, cur) => {
        // 倒序（新的行为放前面）
        acc.goodsIds.unshift(cur?.activity_param?.goods_id)
        acc.timeStamp.unshift(cur?.timestamp)
        acc.pageName.unshift(cur?.page_name)

        return acc
      },
      { goodsIds: [], timeStamp: [], pageName: [] }
    )

    return cycleCartInfo
  }

  return { goodsIds: [], timeStamp: [], pageName: [] }
}

const handleClickAddCollect = (collectData, cycleId) => {
  const clickAddCollectInfo = collectData?.[cycleId]?.[CLICK_ADD_COLLECT_SA_NAME]
  if (clickAddCollectInfo?.length) {
    const cycleFavInfo = clickAddCollectInfo.reduce(
      (acc, cur) => {
        // 倒序（新的行为放前面）
        acc.goodsIds.unshift(cur?.activity_param?.goods_id)
        acc.timeStamp.unshift(cur?.timestamp)
        acc.pageName.unshift(cur?.page_name)

        return acc
      },
      { goodsIds: [], timeStamp: [], pageName: [] }
    )

    return cycleFavInfo
  }

  return { goodsIds: [], timeStamp: [], pageName: [] }
}

const handleClickCheckout = (collectData, cycleId) => {
  const clickCheckoutInfo = collectData?.[cycleId]?.[CLICK_CHECKOUT_SA_NAME]
  if (clickCheckoutInfo?.length) {
    const cycleCheckoutInfo = clickCheckoutInfo.reduce(
      (acc, cur1) => {
        const goodsListArr =
          cur1?.activity_param?.goods_list.split(',') || []

        const eachCheckoutInfo = goodsListArr.reduce(
          (acc, cur2) => {
            acc.goodsIds.push(cur2.split('`')[0])
            acc.timeStamp.push(cur1?.timestamp)
            acc.pageName.push(cur1?.page_name)

            return acc
          },
          { goodsIds: [], timeStamp: [], pageName: [] }
        )

        // 倒序（新的行为放前面）
        acc.goodsIds.unshift(...eachCheckoutInfo.goodsIds)
        acc.timeStamp.unshift(...eachCheckoutInfo.timeStamp)
        acc.pageName.unshift(...eachCheckoutInfo.pageName)

        // 最多保留30条数据
        acc.goodsIds.splice(30)
        acc.timeStamp.splice(30)
        acc.pageName.splice(30)

        return acc
      },
      { goodsIds: [], timeStamp: [], pageName: [] }
    )

    return cycleCheckoutInfo
  }

  return { goodsIds: [], timeStamp: [], pageName: [] }
}

const handleCycleBrowse = (collectData, cycleId) => {

  const detailPvInfo = collectData?.[cycleId]?.[GOODS_BROWSE_PV_KEY_PRIVITE]
  if (!detailPvInfo?.length) return {}

  const list = []
  detailPvInfo.forEach(d => {
    const { timestamp, end_time, goods_id } = d
    if (!goods_id || !timestamp) return

    const _isStart = !end_time // 不存在end_time，说明是开始
    if (_isStart) {
      list.push({
        goods_id,
        timeStamp: timestamp,
        stayTime: 0,
      })
      return
    }

    const _last = list[list.length - 1]
    if (_last?.goods_id !== goods_id) return
    _last.stayTime = (timestamp - _last.timeStamp) / 1000 // 停留时间，单位秒
  })

  const mergeList = list.reduce((mergeList, d) => { // 去重 合并同个goodsId的数据
    const _sameItem = mergeList.find(item => item.goods_id === d.goods_id)
    if (_sameItem) {
      _sameItem.stayTime += d.stayTime // 累计停留时间
      if (d.timeStamp < _sameItem.timeStamp) { // 取最早曝光的时间戳
        _sameItem.timeStamp = d.timeStamp
      }
    } else {
      mergeList.push(d)
    }
    return mergeList
  }, [])

  // 倒序新的在前面
  mergeList?.reverse?.()
  const _mergeList = mergeList?.filter(d => {
    if (!d.goods_id || !d.timeStamp || !d.stayTime) return false // 过滤无效字段
    return true
  })?.splice?.(0, GOODS_BROWSE_MAX_LEN) // 最多保留30条数据

  if (!_mergeList?.length) return {}

  const goodsIds = []
  const timeStamp = []
  const stayTime = []


  _mergeList.forEach(d => {
    goodsIds.push(d.goods_id)
    timeStamp.push(d.timeStamp)
    stayTime.push(d.stayTime?.toFixed(0)) // 取整
  })

  return {
    goodsIds,
    timeStamp,
    stayTime,
  }
}

const filterCycleBrowse = (data, collectData) => {
  if (data?.activity_name !== PAGE_VIEW_SA_NAME) return
  const isDetailPage = data?.page_name === 'page_goods_detail'
  if (!isDetailPage) return

  const cycleId = Object.keys(collectData)[0]
  collectData[cycleId][GOODS_BROWSE_PV_KEY_PRIVITE] = collectData[cycleId][GOODS_BROWSE_PV_KEY_PRIVITE] || []
  collectData[cycleId][GOODS_BROWSE_PV_KEY_PRIVITE].push({
    timestamp: data.timestamp,
    end_time: data.end_time,
    goods_id: data?.page_param?.goods_id,
  })
}

const config = {
  featureKey: 'search_cycle', // 场景key
  singleKey: true, // 是否单例，如果是单例，那么只会有一个sessionId
  closeSessionStorage: false, // 是否使用 sessionStorage 存储数据
  openBeforeStartRouterClear: true, // 回到开始目标上一个路由时结束清空
  // 需要收集的埋点
  saNames: [
    {
      saName: PAGE_VIEW_SA_NAME,
      limit: 5
    },
    {
      saName: SEARCH_KEYWORDS_SA_NAME,
      limit: 5
    },
    {
      saName: CLICK_GOODS_SA_NAME,
      limit: 30
    },
    {
      saName: CLICK_ADD_BAG_SA_NAME,
      limit: 30
    },
    {
      saName: CLICK_ADD_COLLECT_SA_NAME,
      limit: 30
    },
    {
      saName: CLICK_CHECKOUT_SA_NAME,
      limit: 30
    },
  ], 
  // cycle起始页面（回退到起始页面的上一层页面，cycle行为会被清空）
  targetNames: [
    {
      name: 'pre_search',
      url: '/presearch'
    },
    {
      name: newSearchContainer ? 'search-product-list' : 'product-list-v2',
      url: `/${SEARCH_PAGE_PATH}`
    },
  ],
  // cycle行为数据需要被清空的路由
  clearDataNames: [
    {
      name: 'config_index',
      url: '/'
    }
  ],
  handleAction(data, collectData) { // 过滤数据, 每次埋点都会触发
    filterCycleBrowse(data, collectData)
    // 处理搜索词的来源页面
    const processedDataFromSearchkeywords = handleFromPage4SearchKeywords(data, collectData)
    if (processedDataFromSearchkeywords !== 'next') return processedDataFromSearchkeywords

    // 过滤跳转商详
    const clickToDetailSaInfo = filterClickToDetail(data)
    if (clickToDetailSaInfo !== 'next') return clickToDetailSaInfo

    // 过滤加车成功
    const addCartSuccSaInfo = filterAddCartSucc(data)
    if (addCartSuccSaInfo !== 'next') return addCartSuccSaInfo

    // 过滤收藏成功
    const addCollectSaInfo = filterAddCollectSucc(data)
    if (addCollectSaInfo !== 'next') return addCollectSaInfo

    // 过滤checkout
    const clickCheckoutSaInfo = filterClickCheckout(data)
    if (clickCheckoutSaInfo !== 'next') return clickCheckoutSaInfo

    return data
  },
  onTrigger({ collectData, routerAction }) {
    const cycleId = Object.keys(collectData)[0] // 时间戳 作为 cycleId
    const cycleKeywordsInfo = handleSearchKeywords(collectData, cycleId) // 搜索词
    const cycleClickGoodsInfo = handleClickGoods(collectData, cycleId) // 点击商品跳转商详
    const cycleCartInfo = handleClickAddBag(collectData, cycleId) // 加车
    const cycleFavInfo = handleClickAddCollect(collectData, cycleId) // 收藏
    const cycleCheckoutInfo = handleClickCheckout(collectData, cycleId) // 结算
    const cycleBrowseInfo = handleCycleBrowse(collectData, cycleId) // 商详浏览信息
    
    return {
      search_cycle: {
        rt_f_search_cycle_id: cycleId, // cycle开始的时间戳

        rt_f_search_cycle_keywords: cycleKeywordsInfo.keywords.join(),
        rt_f_search_cycle_keywords_ts: cycleKeywordsInfo.timeStamp.join(),
        rt_f_search_cycle_keyword_entry_from: cycleKeywordsInfo.entryFrom.join(),

        rt_f_search_cycle_click: cycleClickGoodsInfo.goodsIds.join(),
        rt_f_search_cycle_click_ts: cycleClickGoodsInfo.timeStamp.join(),
        rt_f_search_cycle_click_pn: cycleClickGoodsInfo.pageName.join(),

        rt_f_search_cycle_cart: cycleCartInfo.goodsIds.join(),
        rt_f_search_cycle_cart_ts: cycleCartInfo.timeStamp.join(),
        rt_f_search_cycle_cart_pn: cycleCartInfo.pageName.join(),

        rt_f_search_cycle_fav: cycleFavInfo.goodsIds.join(),
        rt_f_search_cycle_fav_ts: cycleFavInfo.timeStamp.join(),
        rt_f_search_cycle_fav_pn: cycleFavInfo.pageName.join(),

        rt_f_search_cycle_checkout: cycleCheckoutInfo.goodsIds.join(),
        rt_f_search_cycle_checkout_ts: cycleCheckoutInfo.timeStamp.join(),
        rt_f_search_cycle_checkout_pn: cycleCheckoutInfo.pageName.join(),

        rt_f_search_cycle_browse: cycleBrowseInfo?.goodsIds?.join(),
        rt_f_search_cycle_browse_ts: cycleBrowseInfo?.timeStamp?.join(),
        rt_f_search_cycle_browse_stay_time: cycleBrowseInfo?.stayTime?.join(),

      }
    }
  }
}

export default config
