export const SEARCH_PAGE_PATH = 'pdsearch/.+'
export const REAL_CLASS_PAGE_PATH = '.+-v?c-\\d+\\.html'
export const DAILY_NEW_PAGE_PATH = 'daily-new\\.html'
export const SELECT_CLASS_PAGE_PATH = '.+-sc-\\w+\\.html'
export const SELLING_POINT_PAGE_PATH = 'sellingpoint-\\d+-\\d+-.+\\.html'
export const PICKS_PAGE_PATH = '/*([a-z0-9]+-picks|romwe-[a-z]+|promo-discount).html'

export const SEARCH_PAGE_ROUTE_PATH = ':pathMatch(pdsearch/.+)'
export const REAL_CLASS_PAGE_ROUTE_PATH = ':catchAll(.+)-:_(v)?c-:catId(\\d+).html'
export const DAILY_NEW_PAGE_ROUTE_PATH = 'daily-new.html'
export const SELECT_CLASS_PAGE_ROUTE_PATH = ':catchAll(.+)-sc-:scId(\\w+).html'
export const SELLING_POINT_PAGE_ROUTE_PATH = 'sellingpoint-:tagId(\\d+)-:tagValId(\\d+)-:tagValNameEn(.+).html'
export const PICKS_PAGE_ROUTE_PATH = '/:langPath?/:_([a-z0-9]+-picks|romwe-[a-z]+|promo-discount).html'

export const LIST_PAGE_PATHS = [
  SEARCH_PAGE_PATH,
  DAILY_NEW_PAGE_PATH,
  REAL_CLASS_PAGE_PATH,
  SELECT_CLASS_PAGE_PATH,
  SELLING_POINT_PAGE_PATH,
]

export const DETAIL_PAGE_PATHS = [
  '/*-p-(\\d+)-cat-(\\d+).html',
  '/*-p-(\\d+).html',
  '/*-p-(\\d+)-cat-(|).html',
]

export const STORE_PAGE_PATHS = [
  'store/home',
  '\.+-store-\\d{10}.html' // store reg
]
