import { config, regExpConfig } from './needCollectPathCconfig'

const filterEnvs = [
  // 'localhost',
  // 'debug',
  // 'gray'
]
let reportUrl = ''

// 过滤路由
const filterRoutesList = [
]
// 需要上报的正则路由
const includeRoutes = [
  /^\/(api\/)?(cart|user|checkout|getCouponListForOrderStore|getCouponListForOrder|pay|ltspc)/
]
// 过滤特殊字段
const filterSpecailArea = ['language', 'theme', 'langText']
// 过滤的 response code, Number 类型 
const filterResponseCode = [0, 1000, -1, 200]

const { NODE_SERVER_ENV, langPath, REPORT_URL } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

class ApiReport {
  constructor () {
    this.init()
  }

  init () {
    if (typeof $ === 'undefined') return
    $(document).on('ajaxComplete', (evt, xhr, opts) => {
      this.sendReport(evt, xhr, opts)
    })
  }

  isFilterRoute (opts) {
    const pathname = opts && opts.url && opts.url.split('?')[0]
    return filterRoutesList.includes(pathname)
  }

  isIncluseRoutes (path) {
    const pathname = path
    return includeRoutes.some(v => {
      return v.test(pathname)
    })
  }

  isReportCode (res = {}, xhr) {
    const code = res && Object.prototype.hasOwnProperty.call(res, 'code') ? res.code : 'undefined'
    if (code === 'undefined') {
      // code 未定义时，staus 为非200的时候上报
      return xhr && xhr.status != 200
    }
    return !filterResponseCode.includes(parseInt(code, 10))
  }

  isFilterEnvs () {
    return filterEnvs.includes(NODE_SERVER_ENV)
  }

  isFilterArea (k) {
    return filterSpecailArea.includes(k)
  }

  transformResponse (xhr) {
    let res = {}
    try {
      if (xhr.responseType && xhr.responseType == 'json') {
        res = xhr.response || xhr.responseJson
      } else {
        if (xhr.responseText) {
          try {
            res = JSON.parse(xhr.responseText)
          } catch (error) {
            res = {}
          }
          // res = JSON.parse(xhr.responseText)
        }
      }
    } catch (e) {
      console.log(xhr)
      console.log(e)
    }
    return res 
  }

  parseString (v) {
    try {
      return JSON.stringify(v)
    } catch (e) {
      return ''
    }
  }
  
  // 展开第一层数据
  formatReportParams (baseParams, res = {}, xhr, opts = {}) {
    if (typeof res === 'object') {
      Object.keys(res).forEach(k => {
        if (this.isFilterArea(k)) return
        if (typeof res[k] === 'object') {
          baseParams[k] = this.parseString(res[k])
        } else {
          baseParams[k] = res[k]
        }
      })
    }
    baseParams = this.addRequstParams(baseParams, xhr, opts)
    return baseParams
  }

  parseRequestParams (str, contentType = '') {
    if (Object.prototype.toString.call(str) == '[object Object]') {
      return str
    }
    let ret = Object.create(null)
    if (Object.prototype.toString.call(str) == '[object String]') {

      if (contentType.includes('application/json')) { // json字符串
        try {
          ret = JSON.parse(str)
        } catch (e) { /* empty */ }
        return ret
      }
      
      str = str.trim().replace(/^(\?|#|&)/, '')

      if (!str) {
        return ret
      }

      str.split('&').forEach(function (param) {
        const parts = param.replace(/\+/g, ' ').split('=')
        let key = parts.shift()
        let val = parts.length > 0 ? parts.join('=') : undefined

        key = decodeURIComponent(key)

        if (key.endsWith('[]')) {
          key = key.slice(0, -2)
        }

        if (val === undefined) {
          val = null
        } else {
          try {
            val = decodeURIComponent(val)
          } catch (error) { /* empty */ }
        }

        if (ret[key] === undefined) {
          ret[key] = val
        } else if (Array.isArray(ret[key])) {
          ret[key].push(val)
        } else {
          ret[key] = [ret[key], val]
        }
      })
    }
    return ret
  }

  getRequestData (params, opts) {
    if (opts.type == 'GET') {
      return params.apiSearch || ''
    }
    return opts.data || null
  }

  getNeedParsePath (params) {
    const { apiPath } = params
    let pathConfig = {}
    if (config[apiPath]) {
      pathConfig = config[apiPath]
      return { isNeed: true, pathConfig }
    }
    const p = regExpConfig.find((v) => {
      const { path } = v
      const reg = new RegExp(path)
      if (reg.test(apiPath)) return true
      return false
    })
    if (p) {
      return { isNeed: true, pathConfig: p }
    }
    return { isNeed: false, pathConfig }
  }

  addRequstParams (params, xhr, opts = {}) {
    const data = this.getRequestData(params, opts)
    
    // 没有数据
    if (!data) return params

    const { isNeed, pathConfig } = this.getNeedParsePath(params)
    
    // 非收集路由
    if (!isNeed) return params
    const requestType = opts.headers?.['Content-Type'] || opts.headers?.['content-type'] || ''
    const obj = this.parseRequestParams(data, requestType)

    const { includes = [], excludes = [], all } = pathConfig
    let requestParams = {}
    
    // 收集全部异常参数
    if (all) {
      requestParams = obj
      params.requestParams = JSON.stringify(requestParams)
      return params
    }
    // 收集特定异常参数
    if (includes.length > 0) {
      includes.forEach((k) => {
        if (typeof obj[k] !== 'undefined') {
          requestParams[k] = obj[k]
        }
      })
      params.requestParams = JSON.stringify(requestParams)
      return params
    }

    // 排除特定异常参数
    if (excludes.length > 0) {
      Object.keys(obj).forEach((k) => {
        if (!excludes.includes(k)) {
          requestParams[k] = obj[k]
        }
      })
      params.requestParams = JSON.stringify(requestParams)
      return params
    }
    
    params.requestParams = JSON.stringify(requestParams)

    return params
  }

  formatApiPath (str) {
    const index = str.indexOf('/ltspc')
    if (index >= 0) {
      return str.slice(index)
    }
    return str.replace(langPath, '')
  }

  getPublicParams (xhr, opts) {
    const obj = {}
    let [apiPath = '', apiSearch = ''] = opts.url && opts.url.split('?') || []

    const el = document.createElement('a')
    el.href = apiPath
    apiPath = el.pathname
    
    obj.apiPath = this.formatApiPath(apiPath)
    this.customParam({ obj, opts })

    obj.apiSearch = apiSearch
    obj.userAgent = navigator && navigator.userAgent || ''

    obj.page_name = (
      window.getSaPageInfo && window.getSaPageInfo.page_name ||
      window.SaPageInfo && window.SaPageInfo.page_name ||
      ''
    )
    obj.status_code = xhr.status
    obj.cookieSize = document && document.cookie && document.cookie.length
    obj.hrefSize = location && location.href && location.href.length
    return obj
  }

  customParam({ obj, opts }){
    if( obj.apiPath.indexOf('/unifiedPay') > -1 ){
      let arr = opts.data.split('&')
      arr = arr.filter(item => item.indexOf('paymentCode') > -1)
      arr = arr[0] && arr[0].split('=') || []
      const payment_code = arr[1] || ''
      obj.payment_code = payment_code
    }
  }

  sendReport (evt, xhr = {}, opts = {}) {
    try {
      if (this.isFilterEnvs()) return
      
      const res = this.transformResponse(xhr)
      if (!this.isReportCode(res, xhr)) return

      const params = this.getPublicParams(xhr, opts)
      if (this.isFilterRoute(opts)) return
      if (!this.isIncluseRoutes(params.apiPath)) return

      if (typeof sa === 'undefined') return
      
      this.formatReportParams(params, res, xhr, opts)
      // console.log(params)
      this.report(params)
    } catch (e) {
      console.log(e)
    }
  }

  // 兼容axios response 参数格式
  sendReportForScHttp (response) {
    this.sendReport(null, response?.request, response?.config)
  }

  report(params) {
    try {
      if (typeof sa === 'undefined') {
        return
      }
      if (!reportUrl) {
        const { SA_REPORT_URL } = REPORT_URL
        reportUrl = `${SA_REPORT_URL}/orgSsaUnusual`
      }
      window.sa('error', reportUrl, params)
    } catch (e) {
      console.log(e) 
    }
  }
}

// eslint-disable-next-line no-new
let apiReport = {}
try {
  apiReport = new ApiReport()
} catch (e) { /* empty */ }

export default apiReport
